.App {
  min-height: 100vh;
  background-image: linear-gradient(to top, #3f51b1 0%, #5a55ae 13%, #7b5fac 25%, #8f6aae 38%, #a86aa4 50%, #cc6b8e 62%, #f18271 75%, #f3a469 87%, #f7c978 100%);
}
.search-form{
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-bar {
  width: 50%;
  border: none;
  padding: 10px;
}
.search-button {
  background-color: lightcoral;
  border: none;
  padding: 10px 20px;
  color: white;
}
.recipes {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}